import React from 'react';

const FreedomBanner2 = () => {
  return (
    <div className="bg-ganen-gray-2">
      <div className="box-container">
        <div className="row h-500 h-md-600 px-3">
          <div className="col-12 col-lg-6">
            <div className="embed-responsive embed-responsive-16by9 w-100 h-270 mt-0 mt-lg-5">
              <video className="embed-responsive-item" controls>
                <source
                  src="/videos/ganen-gate-automation.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
          <div className="col-12 col-lg-6 text-center">
            <h5 className="fs-20 ganen-green-bold pb-2 pt-3">
              Auto Gate Integration
            </h5>

            <p>
              <em>
                “Connect your auto gate online
                <br className="d-none d-sm-block" />
                and open it for anyone, anywhere.”
              </em>
            </p>

            <div className="y-2 p-lg-4">
              <div>
                ​Never worry about replacing a faulty switch
                <br className="d-none d-sm-block" />
                ​when everything is wireless.{' '}
                <br className="d-none d-sm-block" />
                ​Open your auto gate remotely and customise and automation
                <br className="d-none d-sm-block" />
                to turn on the car porch lights when you return.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreedomBanner2;
