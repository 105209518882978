import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';

import ProductThumbnailInfo from './product-thumbnail-info';

const ImmersiveBanner3 = () => {
  const { glasswall, outdoor, nest } = useStaticQuery(graphql`
    query {
      glasswall: file(relativePath: { eq: "c3-glass-wall.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      outdoor: file(relativePath: { eq: "pt-automated-outdoor.png" }) {
        childImageSharp {
          fluid(maxWidth: 89, maxHeight: 70, quality: 85) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      nest: file(relativePath: { eq: "pt-google-nest-mini-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 89, maxHeight: 70, quality: 85) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <div className="bg-ganen-gray-2">
      <div className="box-container">
        <div className="row h-500 h-md-750 px-3">
          <div className="col-12 col-lg-6">
            <BackgroundImage
              fluid={glasswall.childImageSharp.fluid}
              className="h-400 h-md-270 w-md-75 mx-auto"
            ></BackgroundImage>
          </div>
          <div className="col-12 col-lg-6 text-center px-1">
            <h5 className="fs-20 ganen-green-bold pb-2 pt-3">It's Hot</h5>

            <p>
              <em>“Climate Control.”</em>
            </p>

            <div className="y-2 p-lg-4">
              Cooldown the house on the go.
              <br className="d-none d-sm-block" />
              ​Our blinds will close and keep the cool air in
              <br className="d-none d-sm-block" />
              ​and let your AC run efficiently.
            </div>

            <div className="row col-12 py-3 d-flex mx-auto px-1 mb-5">
              <div className="col-8 mx-auto d-flex p-1">
                <div className="col-6 flex-grow-1 px-1">
                  <ProductThumbnailInfo
                    image={outdoor.childImageSharp.fluid}
                    title="Automated Outdoor SPAC3 Blinds GB35E"
                    link="/our-products/lifestyle/curtains-blinds/automated-outdoor-spac3-blinds-gb35e"
                  />
                </div>
                <div className="col-6 flex-grow-1 px-1">
                  <ProductThumbnailInfo
                    image={nest.childImageSharp.fluid}
                    title="IR Universal Remote"
                    link="/our-products/lifestyle/ac-av-fan-control/ir-universal-remote"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImmersiveBanner3;
