import React from 'react';

import Img from 'gatsby-image';
import { Link } from 'gatsby';

const ProductThumbnailInfo = ({ image, title, link }) => {
  return (
    <div className="bg-white rounded-10 ganen-border-green text-center py-2 h-100 d-flex flex-column">
      <div>
        <Link to={link}>
          <Img fluid={image} className="mx-auto mb-2" />
        </Link>
      </div>

      <div className="fs-12 ganen-bold mw-120 mx-auto flex-grow-1 p-1">
        {title}
      </div>

      <Link to={link} className="fs-12 text-orange text-decoration-none pt-1">
        Learn more
      </Link>
    </div>
  );
};

export default ProductThumbnailInfo;
