import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';

import ProductThumbnailInfo from './product-thumbnail-info';

const ImmersiveBanner2 = () => {
  const { sofagirl, nest2, ledlights, wifibulb } = useStaticQuery(graphql`
    query {
      sofagirl: file(relativePath: { eq: "c3-girl-on-sofa.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      nest2: file(relativePath: { eq: "pt-nest-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 89, maxHeight: 70, quality: 85) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      ledlights: file(relativePath: { eq: "pt-led-lights.png" }) {
        childImageSharp {
          fluid(maxWidth: 89, maxHeight: 70, quality: 85) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      wifibulb: file(relativePath: { eq: "pt-wifi-bulb.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 89, maxHeight: 70, quality: 85) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <div className="bg-ganen-gray-2">
      <div className="box-container">
        <div className="row h-500 h-md-750 px-3">
          <div className="col-12 col-lg-6">
            <BackgroundImage
              fluid={sofagirl.childImageSharp.fluid}
              className="h-400 h-md-270 w-md-75 mx-auto"
            ></BackgroundImage>
          </div>
          <div className="col-12 col-lg-6 text-center px-1">
            <h5 className="fs-20 ganen-green-bold pb-2 pt-3">Chillout</h5>

            <p>
              <em>“Wind down, have a drink, relax.”</em>
            </p>

            <div className="y-2 p-lg-4">
              <div>
                Be it winding down the day with your loved ones,
                <br className="d-none d-sm-block" />
                ​or chilling out with friends over a drink,
                <br className="d-none d-sm-block" />
                ​trigger the scene with just a command and
                <br className="d-none d-sm-block" />
                immediately set yourselves into the mood for a relaxing time.
              </div>
            </div>

            <div className="row col-12 py-3 d-flex mx-auto px-1 mb-5">
              <div className="col-4 flex-grow-1 px-1">
                <ProductThumbnailInfo
                  image={nest2.childImageSharp.fluid}
                  title="Google Nest Mini"
                  link="/our-products/lifestyle/voice-assistant/google-nest-mini"
                />
              </div>
              <div className="col-4 flex-grow-1 px-1">
                <ProductThumbnailInfo
                  image={ledlights.childImageSharp.fluid}
                  title="BOLD LED RGBW Strip Lights G510S"
                  link="/our-products/BOLD-LED-Lighting/LED-RGB-Lights/BOLD-LED-RGBW-Strip-Lights-G510S"
                />
              </div>
              <div className="col-4 flex-grow-1 px-1">
                <ProductThumbnailInfo
                  image={wifibulb.childImageSharp.fluid}
                  title="BOLD LED RGBW Light Bulb GLE7"
                  link="/our-products/bold-led-lighting/led-rgb-lights/bold-led-rgbw-light-bulb-gle7"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImmersiveBanner2;
