import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';

const HomeAutomationBanner2 = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "c2-family-christmas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <BackgroundImage
      fluid={image.childImageSharp.fluid}
      className="home-automation-banner"
      style={{ height: '500px', backgroundPosition: 'center top' }}
    >
      <div className="box-container d-flex h-100 mx-auto">
        <div className="banner-content text-white w-100 flex-column align-self-center ">
          <h1 className="fs-60 ganen-bold mb-0 text-center d-block">
            Endless Possibilities
          </h1>

          <div className="fs-20 ganen-bold text-center d-block">
            Look at your home in a different light with home automation
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default HomeAutomationBanner2;
