import React from 'react';

import HomeAutomationBanner1 from './home-automation-banner-1';
import HomeAutomationBanner2 from './home-automation-banner-2';
import MerryGoRound from '../../../components/widgets/merry-go-round';

const HomeAutomationCarousel = () => {
  const items = [
    {
      key: 'index-1',
      src: '',
      content: <HomeAutomationBanner1 />,
    },
    {
      key: 'index-2',
      src: '',
      content: <HomeAutomationBanner2 />,
    },
  ];

  return (
    <section>
      <MerryGoRound items={items} fixHeight={500} />
    </section>
  );
};

export default HomeAutomationCarousel;
