import React from 'react';

import ImmersiveBanner1 from './immersive-banner-1';
import ImmersiveBanner2 from './immersive-banner-2';
import ImmersiveBanner3 from './immersive-banner-3';
import MerryGoRound from '../../widgets/merry-go-round';

const ImmersiveCarousel = () => {
  const items = [
    {
      key: 'index-1',
      src: '',
      content: <ImmersiveBanner1 />,
    },
    {
      key: 'index-2',
      src: '',
      content: <ImmersiveBanner2 />,
    },
    {
      key: 'index-3',
      src: '',
      content: <ImmersiveBanner3 />,
    },
  ];

  return (
    <section className="black-carousel-controls immersive-carousel">
      <MerryGoRound items={items} />
    </section>
  );
};

export default ImmersiveCarousel;
