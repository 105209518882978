import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';

const FreedomBanner1 = () => {
  const { bathroom } = useStaticQuery(graphql`
    query {
      bathroom: file(relativePath: { eq: "c5-bathroom.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="bg-ganen-gray-2">
      <div className="box-container">
        <div className="row h-500 h-md-600 px-3">
          <div className="col-12 col-lg-6">
            <BackgroundImage
              fluid={bathroom.childImageSharp.fluid}
              className="h-400 h-md-270 w-md-75 mx-auto"
            ></BackgroundImage>
          </div>
          <div className="col-12 col-lg-6 text-center">
            <h5 className="fs-20 ganen-green-bold pb-2 pt-3">
              Turn on Water Heaters remotely
            </h5>

            <p>
              <em>“Turn off the water heater in 15 minutes...”</em>
            </p>

            <div className="y-2 p-lg-4">
              <div>
                Have instant hot water when you get home,
                <br className="d-none d-sm-block" />
                and never forget to switch off your water heater
                <br className="d-none d-sm-block" />
                ​with our automated countdown timers!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreedomBanner1;
