import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import ProductThumbnailInfo from './product-thumbnail-info';

const ImmersiveBanner1 = () => {
  const { curtain, kaes, nest } = useStaticQuery(graphql`
    query {
      curtain: file(relativePath: { eq: "pt-automated-curtains-gc82e.png" }) {
        childImageSharp {
          fluid(maxWidth: 89, maxHeight: 70, quality: 85) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      kaes: file(relativePath: { eq: "pt-kaes-single-gang-rocker.png" }) {
        childImageSharp {
          fluid(maxWidth: 89, maxHeight: 70, quality: 85) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      nest: file(relativePath: { eq: "pt-google-nest-mini-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 89, maxHeight: 70, quality: 85) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <div className="bg-ganen-gray-2">
      <div className="box-container">
        <div className="row h-500 h-md-750 px-3">
          <div className="col-12 col-lg-6">
            <div className="embed-responsive embed-responsive-16by9 w-75 mx-auto h-270 mt-0 mt-lg-5">
              <iframe
                title="Home Automation - Immersive"
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/OE4gwZKcF9o?rel=0"
                allowFullScreen={true}
              ></iframe>
            </div>
          </div>
          <div className="col-12 col-lg-6 text-center">
            <h5 className="fs-20 ganen-green-bold pb-2 pt-3">Welcome Home</h5>

            <p>
              <em>“Let your home welcome you back after a hard day’s work.”</em>
            </p>

            <div className="py-2 p-lg-4">
              <div>
                ​The lights turn on, the fans turn on, the curtains open.
                <br className="d-none d-sm-block" />
                ​Just sit back, relax and immerse yourself into
                <br className="d-none d-sm-block" />
                ​GANEN’s home automation experience.
              </div>
            </div>

            <div className="row col-12 py-3 d-flex mx-auto px-1 mb-5">
              <div className="col-4 flex-grow-1 px-1">
                <ProductThumbnailInfo
                  image={curtain.childImageSharp.fluid}
                  title="Automated Curtains GC82E"
                  link="/our-products/lifestyle/curtains-blinds/automated-curtains-gc82e"
                />
              </div>
              <div className="col-4 flex-grow-1 px-1">
                <ProductThumbnailInfo
                  image={kaes.childImageSharp.fluid}
                  title="KAES Single Gang Rocker/Push Button Kinetic Switch"
                  link="/our-products/kaes-kinetic-switches/e-series/kaes-single-gang-rocker-push-button-kinetic-switch"
                />
              </div>
              <div className="col-4 flex-grow-1 px-1">
                <ProductThumbnailInfo
                  image={nest.childImageSharp.fluid}
                  title="IR Universal Remote"
                  link="/our-products/lifestyle/ac-av-fan-control/ir-universal-remote"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImmersiveBanner1;
