import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';

const IntuitiveBanner2 = () => {
  const { touch } = useStaticQuery(graphql`
    query {
      touch: file(relativePath: { eq: "c4-phone-touch.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div>
      <div className="box-container">
        <div className="row h-500 h-md-750 px-3">
          <div className="col-12 col-lg-6 text-center">
            <h5 className="fs-20 ganen-green-bold pb-2 pt-3">
              Application Control
            </h5>

            <p>
              <em>“Control it anywhere.”</em>
            </p>

            <div className="y-2 p-lg-4">
              ​Download our GANEN APP and control/monitor anytime,
              <br className="d-none d-sm-block" />
              anywhere! Full control in the palm of your hands.
              <br className="d-none d-sm-block" />
              Forgetting to turn off the lights are days of past.
            </div>
          </div>

          <div className="ol-12 col-lg-6">
            <BackgroundImage
              fluid={touch.childImageSharp.fluid}
              className="h-400 h-md-270 w-md-75 mx-auto"
            ></BackgroundImage>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntuitiveBanner2;
