import React from 'react';

const IntuitiveBanner1 = () => {
  return (
    <div>
      <div className="box-container">
        <div className="row h-500 h-md-750 px-3">
          <div className="ol-12 col-lg-6 text-center">
            <h5 className="fs-20 ganen-green-bold pb-2 pt-3">Switch Control</h5>

            <p>
              <em>“Install it anywhere.”</em>
            </p>

            <div className="y-2 p-lg-4">
              <div>
                ​Sometimes less is more. Simple switches that you can place
                <br className="d-none d-sm-block" />
                ​anywhere, be it by your bedside, on your coffee table,
                <br className="d-none d-sm-block" />
                or tuck it away inside the drawers. Fuss-free!
              </div>
            </div>
          </div>

          <div className="ol-12 col-lg-6">
            <div className="embed-responsive embed-responsive-16by9 w-100 h-270mt-0 mt-lg-5">
              <iframe
                title="Home Automation - Intuitive"
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/3CXziapA5uI?rel=0"
                allowFullScreen={true}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntuitiveBanner1;
