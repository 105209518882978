import React from 'react';

import IntuitiveBanner1 from './intuitive-banner-1';
import IntuitiveBanner2 from './intuitive-banner-2';
import IntuitiveBanner3 from './intuitive-banner-3';
import MerryGoRound from '../../widgets/merry-go-round';

const IntuitiveCarousel = () => {
  const items = [
    {
      key: 'index-1',
      src: '',
      content: <IntuitiveBanner1 />,
    },
    {
      key: 'index-2',
      src: '',
      content: <IntuitiveBanner2 />,
    },
    {
      key: 'index-3',
      src: '',
      content: <IntuitiveBanner3 />,
    },
  ];

  return (
    <section className="black-carousel-controls intuitive-carousel">
      <MerryGoRound items={items} />
    </section>
  );
};

export default IntuitiveCarousel;
