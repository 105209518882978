import React from 'react';

import FreedomBanner1 from './freedom-banner-1';
import FreedomBanner2 from './freedom-banner-2';
import FreedomBanner3 from './freedom-banner-3';
import MerryGoRound from '../../widgets/merry-go-round';

const IntuitiveCarousel = () => {
  const items = [
    {
      key: 'index-1',
      src: '',
      content: <FreedomBanner1 />,
    },
    {
      key: 'index-2',
      src: '',
      content: <FreedomBanner2 />,
    },
    {
      key: 'index-3',
      src: '',
      content: <FreedomBanner3 />,
    },
  ];

  return (
    <section className="black-carousel-controls freedom-carousel">
      <MerryGoRound items={items} />
    </section>
  );
};

export default IntuitiveCarousel;
