import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';

import ProductThumbnailInfo from './product-thumbnail-info';

const IntuitiveBanner3 = () => {
  const { voice, googlehome } = useStaticQuery(graphql`
    query {
      voice: file(relativePath: { eq: "c4-voice-command.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      googlehome: file(relativePath: { eq: "pt-google-home.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 89, maxHeight: 70, quality: 85) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <div>
      <div className="box-container">
        <div className="row h-500 h-md-750 px-3">
          <div className="ol-12 col-lg-6 text-center">
            <h5 className="fs-20 ganen-green-bold pb-2 pt-3">Voice Control</h5>

            <p>
              <em>“Command it anywhere.”</em>
            </p>

            <div className="y-2 p-lg-4">
              <div>
                Recreate your dream automated home just like Jarvis in
                <br className="d-none d-sm-block" />
                Iron Man. We can't build you an armour, but we can definitely
                <br className="d-none d-sm-block" />
                control your lights, AC, TV, fan, curtains, blinds, door lock,
                <br className="d-none d-sm-block" />
                kettle, heater, auto gate and more...
              </div>
            </div>

            <div className="row py-3 d-flex px-1">
              <div className="col-4 flex-grow-1 mx-auto px-1">
                <ProductThumbnailInfo
                  image={googlehome.childImageSharp.fluid}
                  title="Google Home"
                  link="/our-products/lifestyle/voice-assistant/google-home"
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <BackgroundImage
              fluid={voice.childImageSharp.fluid}
              className="h-400 h-md-270 w-md-75 mx-auto"
            ></BackgroundImage>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntuitiveBanner3;
