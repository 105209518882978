import React, { useState, useEffect, useRef } from 'react';

import Layout from '../components/layout/main';
import HomeAutomationCarousel from '../components/content/home-automation/home-automation-carousel';
import Intro from '../components/widgets/intro';
import ContentTitle from '../components/widgets/content-title';
import ImmersiveCarousel from '../components/content/home-automation/immersive-carousel';
import IntuitiveCarousel from '../components/content/home-automation/intuitive-carousel';
import FreedomCarousel from '../components/content/home-automation/freedom-carousel';
import ContactUs from '../components/widgets/contact-us';
import GettingStarted from '../components/widgets/getting-started';
import Head from '../components/layout/head';

import getWindowDimensions from '../utils/getWindowDimensions';

const HomeAutomation = () => {
  const [windowDimensions, setWindowDimensions] = useState();

  useEffect(() => {
    if (window !== null) {
      setWindowDimensions(getWindowDimensions());
    }
  }, []);

  const scrollToImmersive = (ref) => {
    if (window !== null) {
      window.scrollTo(
        0,
        ref.current.offsetTop - (windowDimensions.width <= 767 ? 70 : 130)
      ); // 154px is header height. Need to make this dynamic
    }
  };

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const onclick1 = (evt) => {
    evt.preventDefault();
    scrollToImmersive(ref1);
  };

  const onclick2 = (evt) => {
    evt.preventDefault();
    scrollToImmersive(ref2);
  };

  const onclick3 = (evt) => {
    evt.preventDefault();
    scrollToImmersive(ref3);
  };

  return (
    <Layout>
      <Head title={'Home Automation'} />

      <HomeAutomationCarousel />

      <Intro
        intro={
          <>
            What kind of experience can <br className="d-none d-sm-block" />
            smart home automation offer?
          </>
        }
        title1="Immersive"
        title2="Intuitive"
        title3="Freedom"
        onclick1={onclick1}
        onclick2={onclick2}
        onclick3={onclick3}
      />

      <div ref={ref1} />
      <div className="bg-ganen-gray-2">
        <ContentTitle title="Immersive" bgColor="#efefee" />
        <ImmersiveCarousel />
      </div>

      <div ref={ref2} />
      <div className="bg-white">
        <ContentTitle title="Intuitive" />
        <IntuitiveCarousel />
      </div>

      <div ref={ref3} />
      <div className="bg-ganen-gray-2">
        <ContentTitle title="Freedom" bgColor="#efefee" />
        <FreedomCarousel />
      </div>

      <ContactUs />

      <GettingStarted />
    </Layout>
  );
};

export default HomeAutomation;
